import { Fragment } from 'react';
// import ReactDOM from 'react-dom';
import { hydrate, render } from "react-dom";
import { Router } from './router';
import { GlobalStyle } from './global.styles';
import { DependenciesProvider } from './shared/contexts/dependencies';

const rootElement = document.getElementById("root");

const App = () => (
	<Fragment>
 		<DependenciesProvider>
 			<GlobalStyle />
 			<Router />
 		</DependenciesProvider>
 	</Fragment>
)


if (rootElement?.hasChildNodes()) {
	hydrate(<App />, rootElement);
  } else {
	render(<App />, rootElement);
  }

// ReactDOM.render(
// 	<Fragment>
// 		<DependenciesProvider>
// 			<GlobalStyle />
// 			<Router />
// 		</DependenciesProvider>
// 	</Fragment>,
// 	document.getElementById('root')
// );